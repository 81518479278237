<template lang="pug">
  .app-container.users-list
    .filter-container
      el-input(v-model="query.name" placeholder="Имя" style="width: 200px;" clearable class="filter-item" @keyup.enter.native="handleFilter")
      el-input(v-model="query.email" placeholder="E-mail" style="width: 200px;" clearable class="filter-item" @keyup.enter.native="handleFilter")
      el-select(v-model="query.role" placeholder="Роль" clearable style="width: 200px" class="filter-item" @change="handleFilter")
        el-option(v-for="item in roles" :key="item.id" :label="item.name | uppercaseFirst" :value="item.id")
      el-button(v-waves class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter") Найти
      router-link(to="/admin/users/create")
        el-button(class="filter-item" style="margin-left: 10px;" type="primary" icon="el-icon-plus") Создать
      //- el-button(class="filter-item" style="margin-left: 10px;" type="primary" icon="el-icon-plus" @click="handleCreate") Создать
      //- el-button(v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload") Экспорт

    el-table(v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%")
      el-table-column(align="center" label="ID" width="80")
        template(slot-scope="scope")
          span {{ scope.row.id }}

      el-table-column(align="center" label="Имя")
        template(slot-scope="scope")
          span {{ scope.row.name }}

      el-table-column(align="center" label="Email")
        template(slot-scope="scope")
          span {{ scope.row.email }}

      el-table-column(align="center" label="Роль" width="120")
        template(slot-scope="scope")
          span {{ scope.row.roles.join(', ') }}

      el-table-column(align="center" label="Заблокирован" width="130")
        template(slot-scope="scope")
          span {{ scope.row.blocked ? 'да' : 'нет' }}

      el-table-column(align="center" label="Действия" width="500")
        template(slot-scope="scope")
          router-link.users-list__action(:to="'/admin/users/edit/'+scope.row.id")
            // v-if="!scope.row.roles.includes('admin')"
            el-button(type="primary" size="small" icon="el-icon-edit" v-permission="['manage admin__users']") Изменить
          el-button.users-list__action(type="info" size="small" icon="el-icon-copy-document" v-permission="['manage admin__permissions']" @click="handleDuplicate(scope.row.id)") Копировать
          // v-if="!scope.row.roles.includes('admin')"
          el-button.users-list__action(type="warning" size="small" icon="el-icon-edit" v-permission="['manage admin__permissions']" @click="handleEditPermissions(scope.row.id);") Разрешения
          // v-if="scope.row.roles.includes('visitor')"
          el-button.users-list__action(type="danger" size="small" icon="el-icon-delete" v-permission="['manage admin__users']"  @click="handleDelete(scope.row.id, scope.row.name)") Удалить

    pagination(v-show="total>0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getList")

    el-dialog.users-list__permissions-dialog(:visible.sync="dialogPermissionVisible" :title="'Изменить разрешения - ' + currentUser.name")
      .form-container(v-loading="dialogPermissionLoading" v-if="currentUser.name")
        .permissions-container
          .block
            user-permissions(v-model="permissions" :rolePermissions="rolePermissions")
          .clear-left
        div(style="text-align:right;")
          el-button(type="danger" @click="dialogPermissionVisible=false") Отменить

          el-button(type="primary" @click="confirmPermission") Сохранить

    //- el-dialog(:title="'Создать пользователя'" :visible.sync="dialogFormVisible")
      .form-container(v-loading="userCreating")
        el-form(ref="userForm" :rules="rules" :model="newUser" label-position="left" label-width="150px" style="max-width: 500px;")
          el-form-item(:label="$t('user.role')" prop="role")
            el-select(v-model="newUser.role" class="filter-item" placeholder="Please select role")
              el-option(v-for="item in nonAdminRoles" :key="item" :label="item | uppercaseFirst" :value="item")

          el-form-item(:label="$t('user.name')" prop="name")
            el-input(v-model="newUser.name")

          el-form-item(:label="$t('user.email')" prop="email")
            el-input(v-model="newUser.email")

          el-form-item(:label="$t('user.password')" prop="password")
            el-input(v-model="newUser.password" show-password)

          el-form-item(:label="$t('user.confirmPassword')" prop="confirmPassword")
            el-input(v-model="newUser.confirmPassword" show-password)


        .dialog-footer(slot="footer")
          el-button(@click="dialogFormVisible = false") Отменить

          el-button(type="primary" @click="createUser()") Сохранить

</template>
<script>
import Pagination from '@/components/Pagination' // Secondary package based on el-pagination
import UserResource from '@/api/user'
import Resource from '@/api/resource'
import waves from '@/directive/waves' // Waves directive
import permission from '@/directive/permission' // Waves directive
import checkPermission from '@/utils/permission' // Permission checking
import UserPermissions from './UserPermissions'
import { notifyRequest } from '@/utils/api'

const userResource = new UserResource()
const permissionResource = new Resource('permissions')


export default {
  name: 'UserList',
  components: { Pagination, UserPermissions },
  directives: { waves, permission },
  data() {
    var validateConfirmPassword = (rule, value, callback) => {
      if (value !== this.newUser.password) {
        callback(new Error('Password is mismatched!'))
      } else {
        callback()
      }
    }
    return {
      list: null,
      total: 0,
      loading: true,
      downloading: false,
      userCreating: false,
      query: {
        page: 1,
        limit: 15,
        name: null,
        email: null,
        role: null
      },
      roles: this.$store.state.user.allRoles,
      nonAdminRoles: ['editor', 'user', 'visitor'],
      newUser: {},
      dialogFormVisible: false,
      dialogPermissionVisible: false,
      dialogPermissionLoading: false,
      currentUserId: 0,
      currentUser: {
        name: '',
        permissions: [],
        rolePermissions: [],
      },
      rules: {
        role: [{ required: true, message: 'Role is required', trigger: 'change' }],
        name: [{ required: true, message: 'Name is required', trigger: 'blur' }],
        email: [
          { required: true, message: 'Email is required', trigger: 'blur' },
          { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] },
        ],
        password: [{ required: true, message: 'Password is required', trigger: 'blur' }],
        confirmPassword: [{ validator: validateConfirmPassword, trigger: 'blur' }],
      },
      permissionProps: {
        children: 'children',
        label: 'name',
        disabled: 'disabled',
      },
      permissions: [],
      rolePermissions: [],
    }
  },
  computed: {
  },
  created() {
    this.resetNewUser()
    this.getList()
    // if (checkPermission(['manage permission'])) {
    //   this.getPermissions();
    // }
  },
  methods: {
    checkPermission,
    reload() {
      if (this.total === this.query.limit * (this.query.page - 1)) {
        this.query.page--
      }
      this.getList()
    },
    async getPermissions() {
      const { data } = await permissionResource.list({})
      const { all, menu, other } = this.classifyPermissions(data)
      this.permissions = all
      this.menuPermissions = menu
      this.otherPermissions = other
    },

    async getList() {
      const { limit, page } = this.query
      this.loading = true
      const { data, meta } = await userResource.list(this.query)
      this.list = data
      this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1
      })
      this.total = meta.total
      this.loading = false
    },
    handleFilter() {
      this.query.page = 1
      this.getList()
    },
    handleCreate() {
      this.resetNewUser()
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['userForm'].clearValidate()
      })
    },
    handleDelete(id, name) {
      this.$confirm('Данное действие навсегда удаляет роль ' + name + '. Продолжить?', 'Warning', {
        confirmButtonText: 'Подтвердить',
        cancelButtonText: 'Отменить',
        type: 'warning',
      }).then(() => {
        userResource.destroy(id).then(response => {
          this.$message({
            type: 'success',
            message: 'Удаление успешно',
          })
          this.total--
          this.reload()
        }).catch(error => {
          console.log(error)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Удаление отменено',
        })
      })
    },
    async handleEditPermissions(id) {
      this.currentUserId = id
      this.dialogPermissionLoading = true
      this.dialogPermissionVisible = true
      const found = this.list.find(user => user.id === id)
      this.currentUser = {
        id: found.id,
        name: found.name
      }
      const { data } = await userResource.permissions(id)
      this.permissions = data.user
      this.rolePermissions = data.role
      this.dialogPermissionLoading = false
      this.$nextTick(() => {
        // this.$refs.menuPermissions.setCheckedKeys(this.permissionKeys(this.userMenuPermissions));
        // this.$refs.otherPermissions.setCheckedKeys(this.permissionKeys(this.userOtherPermissions));
      })
    },
    handleDuplicate(id) {
      let item = this.list.filter(el => {
        return el.id === id
      })
      if (!item || !item.length) {
        return
      }
      item = item[0]
      this.$confirm(`Копировать "${item.name}" (ID: ${item.id})?`, 'Подтвердите копирование', {
        confirmButtonText: 'Подтвердить',
        cancelButtonText: 'Отменить',
        // type: 'warning'
      }).then(() => {
        this.duplicateItem(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Копирование отменено'
        })
      })
    },
    async duplicateItem(id) {
      const response = await notifyRequest({
        url: `/users/${id}/duplicate`,
        method: 'post'
      }, 'Пользователь успешно скопирован')
      this.reload()
      return response
    },
    resetNewUser() {
      this.newUser = {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        role: 'user',
      }
    },
    handleDownload() {
      this.downloading = true
      /* import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['id', 'user_id', 'name', 'email', 'role'];
        const filterVal = ['index', 'id', 'name', 'email', 'role'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'user-list',
        });
        this.downloading = false;
      });*/
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },

    confirmPermission() {
      this.dialogPermissionLoading = true

      userResource.updatePermission(this.currentUserId, { permissions: this.permissions }).then(response => {
        this.$message({
          message: 'Permissions has been updated successfully',
          type: 'success',
          duration: 5 * 1000,
        })
        this.dialogPermissionLoading = false
        this.dialogPermissionVisible = false
      })
    },
  },
}
</script>

<style lang="sass">
@import "@/assets/sass/blocks/users-list.sass"
</style>
